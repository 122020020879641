import {useState}  from "react";
import { Link } from "react-router-dom";
import style from "./NavBar.module.scss";
import MobileHamburger from "./MobileHamburger/MobileHamburger.component";
import logo from "../../assets/images/logo2.png";
import { FaUserAlt } from "react-icons/fa";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import { BiLogOutCircle } from "react-icons/bi";
import { ZAxis } from "recharts";



const NavBar = ({ avatarUrl, options }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { getLocalStorage } = useLocalStorage("userData");

  const user = JSON.parse(getLocalStorage);
  // const user = JSON.parse(localStorage.getItem("userData"));

  function toggleDropdown() {
    setDropdownVisible(prevState => !prevState);
  }

  

 

  return (
    <div className={style.navbar}>
      <div className={style.navbar_column_one}>
        <MobileHamburger />
        <div className={style.navbar_column_one_brand}>
          <div>
            <Link to='/'>
              <img
                src={logo}
                alt='firs'
                className={style.navbar_column_one_brand_logo}
              />
            </Link>
          </div>
          <div className={style.navbar_column_one_brand_name}>
            <p className={style.navbar_column_one_brand_name_top}>
              sentinel systems
            </p>
            <p className={style.navbar_column_one_brand_name_bottom}>
              federal inland revenue service
            </p>
          </div>
        </div>
      </div>
      <div className={style.navbar_column_two}>
        <p className={style.navbar_column_two_user}>
          Welcome, {user?.firstname}
        </p>
        <div className={style.navbar_column_two_image}>
          <FaUserAlt onClick={toggleDropdown} />
          {dropdownVisible && <DropdownMenu options={options} />}
        </div>
      </div>
    </div>
  );
};

function DropdownMenu({ options }) {

  const { removeLocalStorage } = useLocalStorage("userData");

  const handleLogout = () => {
    removeLocalStorage();
    window.location.href = "/login";
  };

  return (
    <ul style={{
      marginTop: 20, 
      fontSize: 14, 
      position: "absolute",
      top: 30,
      right: 0,
      backgroundColor: "black",
      border: "1px solid black",
      zIndex: 1000,
      padding: 10,
      listStyle: "none",
    }}>
      <li style={{ margin: "5px 0" }}>
        <a 
          style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }} 
          href={"/change-password"}>
          Change Password
        </a>
      </li>

      <li>
        <div 
          style={{ color: 'white', cursor: 'pointer' }}
          onClick={handleLogout}>
          Log out
        </div>
      </li>
    </ul>
  );
}



export default NavBar;


